import { graphql } from "gatsby";
import React from "react";

import Layout from "../components/layout";
import SearchBlock from "../components/search/search";
import Seo from "../components/seo";

const SearchPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Search" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center text-center mt-5 mb-4">Search</h1>
          </div>
          <div className="col-xl-8 mx-auto">
              <SearchBlock />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SearchPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
